<!--
Articles
-->
<template>
  <div v-if="this.data.length > 0">
    <div class="container py-4">
      <div class="row" v-for="(items, key) in chunkedItems(moreLoaded)" :key="key">
        <div class="col-lg-4" v-for="(item, index) in items" :key="index">
          <router-link
              :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
          ><img
              v-if="item.image !== null"
              class="img-fluid"
              style="height: 200px; object-fit: cover"
              v-bind:src="item.image"
              v-bind:alt="item.title"
          /></router-link>
          <div class="clearfix py-3">
            <div class="col col float-start text-lightblue list-date">
              {{ createDate(item.date, "MMM DD. YYYY") }}
            </div>
          </div>
          <div class="text-blue fw-bold fs-5 article-list-h">
            <router-link
                :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
                class="text-blue text-decoration-none"
            >
            {{ item.title }}
            </router-link>
          </div>
          <div class="text-blue fs-7 pt-2 article-list-h" v-html="item.lead"/>
          <router-link
              :to="
              prefix +
              '/' +
              createDate(item.date, 'YYYY/MM/DD') +
              '/' +
              item.link
            "
              class="text-decoration-none px-0 text-blue list-date tovabbBtn"
          >Tovább <font-awesome-icon :icon="['fas', 'arrow-right']"
          />
          </router-link>
        </div>
      </div>

      <div v-if="this.length < this.data.length || this.data.length > this.length" class="container px-0 text-center my-1">
        <a href="#" @click.prevent.stop="loadMore" type="button" class="btn bg-yellow fw-medium">Még több hír</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "module12",
  props: {
    data: {data: [Array]},
    prefix: {data: ["String"], required: true},
  },
  data() {
    return {
      length: 12,
      more: 12
    };
  },
  methods: {
    createDate(date, format) {
      return moment(date).format(format);
    },
    chunkedItems(items) {
      return _.chunk(items.slice(0), 3);
    },
    loadMore() {
      if (this.length > this.data.length) {
        return;
      }
      this.length = this.length + this.more;
    },
  },
  computed: {
    moreLoaded() {
      return this.data.slice(0, this.length);
    },
  },
};
</script>

<style scoped></style>
